import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Nullish } from '@getquin/ts-helpers';
import cn from 'classnames';
import { debounce } from 'lodash';

import {
  CompanyLogo,
  Divider,
  Paper,
  Typography,
  useOutsideClick,
} from '@/ui-kit/web';

import { LanguagePicker } from '@/components/LanguagePicker';
import { LocaleLink } from '@/components/LocaleLink';

import { getAlternatePagesList } from '@/features/seo/utils';

import { getNavigationItems } from '../utils';
import { AuthButtons, SignUpButton } from './AuthButtons';
import { BurgerMenuIcon } from './BurgerMenuIcon';
import * as styles from './HeaderNavigation.module.scss';
import { NavigationList, NavigationListMobile } from './NavigationList';

interface HeaderNavigationProps {
  data: Queries.pageQueryAndPageQueryQuery;
  pageContext: Queries.getPagesQuery['allStrapiLandingPageV2']['nodes'][number];
  pathname: string;
}
export const HeaderNavigation: FC<HeaderNavigationProps> = ({
  data,
  pageContext,
  pathname,
}) => {
  const [hidden, setHidden] = useState(false);
  const locale = pageContext.locale || 'en';
  const alternate = getAlternatePagesList(
    pageContext.Slug,
    pageContext.slugGroup,
    data.allSitePage.nodes,
  );
  const navigationItems = getNavigationItems(data.allMainNav);

  const onScrollDown = useCallback(() => setHidden(true), []);
  const onScrollUp = useCallback(() => setHidden(false), []);
  const debouncedOnScroll = useMemo(
    () => debounce(() => setHidden(false), 3000),
    [],
  );

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      const windowScroll = Math.max(window.scrollY, 0); // prevent safari scroll out of the top
      const direction = lastScrollY < windowScroll ? 'down' : 'up';
      lastScrollY = windowScroll;

      if (direction === 'down') {
        onScrollDown();
      } else {
        onScrollUp();
      }
      debouncedOnScroll();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.page_grid}>
        <Paper
          component="header"
          className={cn(styles.header_container, { [styles.hidden]: hidden })}
        >
          <div className={styles.header_left}>
            <div>
              <Typography.Link
                aria-label="Home"
                component={LocaleLink}
                external={false}
                href="/"
                hrefLang={locale}
              >
                <CompanyLogo />
              </Typography.Link>
            </div>
            <Divider
              type="vertical"
              className={cn(styles.divider, styles.desktop_only)}
            />
            <div className={styles.desktop_only}>
              <LanguagePicker
                position="bottom"
                current={locale}
                alternateList={alternate}
              />
            </div>
          </div>

          <div className={cn(styles.header_midle, styles.desktop_only)}>
            <NavigationList
              navigationItems={navigationItems}
              locale={locale}
              pathname={pathname}
            />
          </div>

          <div className={cn(styles.header_right, styles.desktop_only)}>
            <AuthButtons locale={locale} slug={pageContext.Slug} />
          </div>

          <div className={cn(styles.header_right, styles.mobile_only)}>
            <SignUpButton
              locale={locale}
              slug={pageContext.Slug}
              type="filled"
            />

            <BurgerMenu
              locale={locale}
              navigationItems={navigationItems}
              slug={pageContext.Slug}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

interface BurgerMenuProps {
  locale: string;
  navigationItems: Queries.pageQueryAndPageQueryQuery['allMainNav']['nodes'];
  slug: Nullish<string>;
}

const BurgerMenu: FC<BurgerMenuProps> = memo(
  ({ locale, navigationItems, slug }) => {
    const [opened, setOpened] = useState(false);
    const ref = useOutsideClick<HTMLDivElement>(() => setOpened(false));

    return (
      <div ref={ref}>
        <BurgerMenuIcon
          className={styles.burger}
          onClick={() => setOpened(!opened)}
        />
        {opened && (
          <NavigationListMobile
            navigationItems={navigationItems}
            locale={locale}
            slug={slug}
          />
        )}
      </div>
    );
  },
);
