// extracted by mini-css-extract-plugin
export var burger = "HeaderNavigation-module--burger--da114";
export var desktop_only = "HeaderNavigation-module--desktop_only--b05ca";
export var divider = "HeaderNavigation-module--divider--86bbc";
export var header_container = "HeaderNavigation-module--header_container--ba3f8";
export var header_left = "HeaderNavigation-module--header_left--f33c0";
export var header_midle = "HeaderNavigation-module--header_midle--df825";
export var header_right = "HeaderNavigation-module--header_right--39142";
export var hidden = "HeaderNavigation-module--hidden--7c24c";
export var mobile_only = "HeaderNavigation-module--mobile_only--af75a";
export var page_grid = "HeaderNavigation-module--page_grid--4a188";
export var wrapper = "HeaderNavigation-module--wrapper--813c1";