import { useEffect, useLayoutEffect } from 'react';

import { isClientSide } from '@/utils/helpers';

/**
 * React throws a warning when useLayoutEffect is called on Server, saying that
 * this call will have no effect.
 *
 * Yet sometimes we need `useLayoutEffect` in a component that can be rendered
 * dynamically on the client but it can also be rendered during the SSR (which
 * causes React to throw a weird warning).
 *
 * To suppress it, this util calls layout effect only on client side.
 * @see https://github.com/facebook/react/issues/14927#issuecomment-572717051
 **/
export const useIsomorphicLayoutEffect = isClientSide()
  ? useLayoutEffect
  : useEffect;
