import React, { FC } from 'react';

import { HeroProps } from './@types';
import { HeroInformation } from './components/HeroInformation';
import { HeroMedia } from './components/HeroMedia';
import { MobileButtonVariant } from './constants';
import * as styles from './Hero.module.scss';
import { mobileButtonVariantTypeguard } from './utils';

export const Hero: FC<HeroProps> = ({ block, index, locale }) => {
  return (
    <div className={styles.wrapper}>
      <HeroInformation
        headlineComponent={index === 0 ? 'h1' : 'h2'}
        locale={locale}
        kpis={block.kpis}
        primaryButton={block.primaryButton}
        secondaryButton={block.secondaryButton}
        subtitle={block.subtitle}
        title={block.title}
      />

      <HeroMedia
        mediaLoading={index === 0 ? 'eager' : 'lazy'}
        locale={locale}
        media={block.media}
        mediaMobile={block.mediaMobile}
        mobileButtonVariant={
          mobileButtonVariantTypeguard(block.mobileButtonVariant)
            ? block.mobileButtonVariant
            : MobileButtonVariant.DOWNLOAD
        }
        primaryButton={block.primaryButton}
        secondaryButton={block.secondaryButton}
      />
    </div>
  );
};
