import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { type Components } from 'react-markdown/lib/ast-to-react';
import cn from 'classnames';
import remarkGfm from 'remark-gfm';

import * as styles from './Markdown.module.scss';

interface MarkdownProps {
  className?: string;
  children: string;
  components?: Components;
}

export const Markdown: FC<MarkdownProps> = ({
  className,
  children,
  components,
}) => {
  if (!children) return null;

  return (
    <ReactMarkdown
      className={cn(styles.markdown, className)}
      remarkPlugins={[remarkGfm]}
      components={components}
    >
      {children}
    </ReactMarkdown>
  );
};
