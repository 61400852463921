import { Analytics } from '@/services/analytics';

import { TrackableButtonProps } from './@types';
import { getAttributes } from './utils';

export const useButtonAnalytics = (
  analytics?: TrackableButtonProps['analytics'],
) => {
  const onClick = () => {
    if (!analytics) return;
    Analytics.instance.track(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics.event_name,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getAttributes(analytics.event_attributes?.internal.content),
    );
  };

  return [onClick, analytics?.event_attributes?.internal?.content] as const;
};
