import React, { FC } from 'react';
import { type Components } from 'react-markdown/lib/ast-to-react';
import { Nullish } from '@getquin/ts-helpers';

import { Markdown } from './Markdown';

type RichtextType = Nullish<{
  data: Nullish<{
    childMarkdownRemark: Nullish<{ rawMarkdownBody: Nullish<string> }>;
  }>;
}>;

export interface RichtextProps {
  className?: string;
  richtext: RichtextType;
  components?: Components;
}

export const Richtext: FC<RichtextProps> = ({
  className,
  richtext,
  components,
}) => {
  if (!richtext?.data?.childMarkdownRemark?.rawMarkdownBody) return null;

  return (
    <Markdown className={className} components={components}>
      {richtext.data.childMarkdownRemark.rawMarkdownBody}
    </Markdown>
  );
};
