import React, { FC, PropsWithChildren } from 'react';
import { Nullish } from '@getquin/ts-helpers';

import { Typography } from '@/ui-kit/web';

import { LocaleLink } from '@/components/LocaleLink';

import * as styles from './Section.module.scss';

interface BaseSectionProps {
  title: Nullish<string>;
  contentClassName?: string;
}
export const BaseSection: FC<PropsWithChildren<BaseSectionProps>> = ({
  children,
  contentClassName,
  title,
}) => {
  return (
    <div className={styles.wrapper}>
      {title && (
        <Typography.Headline size="small" weight="regular" color="secondary">
          {title}
        </Typography.Headline>
      )}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

interface LinksSectionProps {
  items: Queries.pageQueryAndPageQueryQuery['allFooterNav']['nodes'][number]['subItems'];
  locale: string;
  title: Nullish<string>;
}
export const LinksSection: FC<LinksSectionProps> = ({
  items,
  locale,
  title,
}) => {
  return (
    <BaseSection title={title} contentClassName={styles.list}>
      {items?.map((item) => {
        if (!item || !item.path) return null;

        return (
          <Typography.Link
            component={LocaleLink}
            external={item.external}
            href={item.path}
            hrefLang={locale}
            key={item.title}
            size="medium"
            weight="bold"
          >
            {item.title}
          </Typography.Link>
        );
      })}
    </BaseSection>
  );
};
