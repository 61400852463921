import React, { FC } from 'react';
import Marquee from 'react-fast-marquee';
import cn from 'classnames';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import StarFilled from '@/ui-kit/icons/star-filled.svg';
import Wheat from '@/ui-kit/icons/wheat.svg';
import { Typography } from '@/ui-kit/web';

import { MainHeadline } from '@/components/MainHeadline';

import { nullishItemListToItemList } from '@/utils/nullish';

import { BlockProps } from '../../@types';
import { BlockGrid } from '../../core/BlockGrid';
import { Testimonial } from './Testimonial';
import * as styles from './Testimonials.module.scss';

type TestimonialsProps =
  BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_TESTIMONIALS'>;

export const Testimonials: FC<TestimonialsProps> = ({ block }) => {
  if (!block.testimonials) return null;

  const testimonials = nullishItemListToItemList(block.testimonials);

  return (
    <BlockGrid className={styles.container}>
      <div className={styles.rating}>
        <Wheat />

        <div className={styles.rating_content}>
          <Typography.Headline component="p" size="medium" weight="bold">
            {block.ratingValue}
          </Typography.Headline>

          <div className={styles.stars}>
            {Array.from({ length: 5 }).map((_, idx) => (
              <StarFilled key={idx} className={styles.star} />
            ))}
          </div>

          <Typography.Headline component="p" size="medium">
            {block.ratingLabel}
          </Typography.Headline>
        </div>

        <Wheat className={styles.right_wheat} />
      </div>

      <MainHeadline align="center">{block.title}</MainHeadline>

      <Marquee
        className={cn(styles.list, styles.list__desktop)}
        loop={0}
        speed={10}
        direction="left"
      >
        {testimonials.map((testimonial, idx) => (
          <Testimonial
            key={idx}
            title={testimonial.Title}
            storeName={testimonial.StoreName}
            name={testimonial.Name}
            text={testimonial.Content}
          />
        ))}
      </Marquee>

      <div className={cn(styles.list, styles.list__mobile)}>
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination]}
        >
          {testimonials.map((testimonial, idx) => (
            <SwiperSlide key={idx}>
              <Testimonial
                title={testimonial.Title}
                storeName={testimonial.StoreName}
                name={testimonial.Name}
                text={testimonial.Content}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </BlockGrid>
  );
};
