import { DeepPartial, Nullable, Option } from '@getquin/ts-helpers';
import { HeadProps } from 'gatsby';
import type { FAQPage, Graph, Organization, Question } from 'schema-dts';

import { nullishItemListToItemList } from '../../utils/nullish';
import { PageBlock } from '../blocks/@types';
import { Alternate } from './@types';

/**
 * Returns the corresponding string value of a given type in the meta social list.
 * @param {string} type - The type to be matched to its corresponding string value.
 * @returns {string} - The corresponding string for the given type, or 'openGraphMeta' if the type is not found.
 */
export const metaSocialListType = (type: string): string =>
  ({
    og: 'openGraphMeta',
    twitter: 'twitterCard',
    fb: 'facebook',
  })[type] || 'openGraphMeta';

export const getContentFromMetaSocial = (
  property: Option<string>,
  metaSocial: Option<DeepPartial<Queries.SeoFragment['metaSocial']>>,
): Nullable<string> => {
  if (!property || !metaSocial?.length) return null;

  const [contentKey, contentValue] = property.split(':');
  if (!contentKey || !contentValue) return null;

  const listFiltered = metaSocial.filter(
    (item) =>
      item?.propertyOrName === contentValue &&
      metaSocialListType(contentKey) === item?.socialNetwork,
  );

  return listFiltered[0]?.content || null;
};

export const getAlternatePagesList = (
  slug: Nullable<string>,
  slugGroup: Nullable<string>,
  sitePageNodes: Queries.pageQueryAndPageQueryQuery['allSitePage']['nodes'],
): Alternate[] => {
  const groupKey = slugGroup ? slugGroup : slug;
  const filteredRoutes = sitePageNodes.filter(
    (route) => route.pageContext?.slugGroup === groupKey,
  );

  return filteredRoutes.map((route) => {
    return {
      locale: route.pageContext?.locale as string,
      path: route.path,
      xDefault: route.pageContext?.locale === 'en',
    };
  });
};

interface GetStructuredDataPayload
  extends Pick<
    HeadProps<
      Queries.pageQueryAndPageQueryQuery,
      Queries.getPagesQuery['allStrapiLandingPageV2']['nodes'][number]
    >,
    'data' | 'pageContext'
  > {
  domain: string;
}

export const getFAQStructuredData = ({
  pageContext,
}: GetStructuredDataPayload): Nullable<FAQPage> => {
  const faqBlock = pageContext?.blocks?.find(
    (item) => item?.__typename === 'STRAPI__COMPONENT_BLOCKS_V_2_FAQ',
  ) as Extract<PageBlock, { __typename: 'STRAPI__COMPONENT_BLOCKS_V_2_FAQ' }>;
  const nonNullishFAQ = faqBlock?.QA?.filter(
    (item) => item?.Question && item.Answer?.data?.Answer,
  ) as NonNullable<(typeof faqBlock)['QA']>;

  if (!nonNullishFAQ?.length) return null;

  return {
    '@type': 'FAQPage',
    mainEntity: nonNullishFAQ.map<Question>((faq) => ({
      '@type': 'Question',
      name: faq?.Question || '',
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq?.Answer?.data?.Answer || '',
      },
    })),
  };
};

export const getOrganizationStructuredData = ({
  domain,
  data,
}: GetStructuredDataPayload): Nullable<Organization> => {
  const defaultSeoNode = data.allStrapiDefaultSeo.nodes[0];
  const globalNode = data.allStrapiGlobal.edges[0]?.node;
  const globalFooter = data.allStrapiGlobal.edges?.[0]?.node?.footer;
  const socialNetworkUrls = nullishItemListToItemList(
    globalFooter?.social_networks?.map((item) => item?.Url),
  );

  const description = defaultSeoNode?.SEO?.metaDescription;
  const logo = `${domain}${globalNode.darkLogo?.[0]?.localFile?.publicURL}`;

  if (!logo || !description) return null;

  return {
    name: 'getquin',
    '@type': 'Organization',
    logo,
    url: domain,
    sameAs: socialNetworkUrls,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: 'info@getquin.com',
        telephone: '',
        contactType: 'customer service',
      },
    ],
  };
};

interface GetGraphProps {
  faq: Nullable<FAQPage>;
  organization: Nullable<Organization>;
}
export const getGraphLdSchema = ({
  faq,
  organization,
}: GetGraphProps): Nullable<Graph> => {
  if (!faq && !organization) return null;

  return {
    '@context': 'https://schema.org',
    '@graph': nullishItemListToItemList([organization, faq]),
  };
};
