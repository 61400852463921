import React, { type FC, type KeyboardEvent, useCallback } from 'react';
import cn from 'classnames';
import cookie from 'cookie';

import ChevronDownOutlined from '@/ui-kit/icons/chevron-down-outlined.svg';
import {
  createAllClassesBuilders,
  Paper,
  Typography,
  useOutsideClick,
} from '@/ui-kit/web';

import {
  LanguageLabelProps,
  LanguagePickerDropdownProps,
  LanguagePickerProps,
} from './@types';
import { LANGUAGE_PICKER_POSITION } from './constants';
import * as styles from './LanguagePicker.module.scss';

const setLanguageCookie = (language: string) => {
  document.cookie = cookie.serialize('lang', language, {
    maxAge: 60 * 24 * 365,
    path: '/',
  });
};

const classesBuilder = createAllClassesBuilders(styles, {
  position: LANGUAGE_PICKER_POSITION,
});

const LanguageLabel: FC<LanguageLabelProps> = ({ active, code }) => {
  return (
    <>
      <Typography size="medium" weight={active ? 'bold' : 'regular'}>
        {code.toUpperCase()}
      </Typography>
      <img
        alt={`${code}'s flag`}
        className={styles.label_image}
        height={16}
        loading="lazy"
        src={`/flags/${code}.svg`}
        width={16}
      />
    </>
  );
};

const LanguagePickerDropdown: FC<LanguagePickerDropdownProps> = ({
  alternateList,
  current,
  position,
  selectLanguage,
  opened,
}) => {
  return (
    <Paper
      className={cn(styles.language_list, classesBuilder.position(position), {
        [styles.hidden]: !opened,
      })}
      spacing={0}
    >
      {alternateList.map(({ locale, path }) => {
        const active = current === locale;
        return (
          <Typography.Link
            key={locale}
            href={path}
            hrefLang={locale}
            onClick={() => selectLanguage(locale)}
            onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) =>
              e.key === 'Enter' && selectLanguage(locale)
            }
            className={cn(styles.language_list_item, {
              [styles.active]: active,
            })}
            tabIndex={0}
          >
            <LanguageLabel code={locale} active={active} />
          </Typography.Link>
        );
      })}
    </Paper>
  );
};

export const LanguagePicker: FC<LanguagePickerProps> = ({
  alternateList,
  current,
  position = 'bottom',
}) => {
  const [opened, setOpened] = React.useState(false);

  const toggleDropdown = useCallback(() => setOpened((prev) => !prev), []);
  const closeDropdown = useCallback(() => setOpened(false), []);
  const selectLanguage = useCallback((language: string) => {
    closeDropdown();
    setLanguageCookie(language);
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(closeDropdown);

  return (
    <div className={styles.wrapper} ref={ref}>
      <div
        className={cn(styles.preview, { [styles.opened]: opened })}
        onClick={toggleDropdown}
        onKeyDown={(e) => e.key === 'Enter' && toggleDropdown()}
        tabIndex={0}
        role="button"
      >
        <div className={styles.current_language}>
          <LanguageLabel code={current} />
        </div>
        <ChevronDownOutlined className={styles.icon} height={16} width={16} />
      </div>

      <LanguagePickerDropdown
        alternateList={alternateList}
        current={current}
        opened={opened}
        position={position}
        selectLanguage={selectLanguage}
      />
    </div>
  );
};
