import React, { Fragment, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';

import '@/ui-kit/web/index.css';
import '@/styles/index.scss';
import '@/services/cookies-consent/index.css';

import { isAnalyticsEnabled } from '@/services/cookies-consent';
import { initFaviconManager } from '@/services/favicon-manager';
import { ConsentManager } from '@/services/segment/ConsentManager';
import { loadSmartlook } from '@/services/smartlook';

import { isClientSide } from '@/utils/helpers';
import { nullishItemListToItemList } from '@/utils/nullish';

import { BlockFactory } from '@/features/blocks';
import { FooterNavigation, HeaderNavigation } from '@/features/navigation';
import { PageHead } from '@/features/seo/PageHead';
import { withAuthGuard } from '@/middlewares/auth';
import { buildGetServerData } from '@/middlewares/common';
import { withLanguageBasedRedirect } from '@/middlewares/language-redirect';

if (isClientSide()) {
  if (isAnalyticsEnabled()) {
    loadSmartlook();
  }
}

const DynamicLandingPage = ({
  data,
  pageContext,
  location,
}: PageProps<
  Queries.pageQueryAndPageQueryQuery,
  Queries.getPagesQuery['allStrapiLandingPageV2']['nodes'][number]
>) => {
  const locale = pageContext.locale || 'en';
  const nonNullishBlocks = nullishItemListToItemList(pageContext.blocks);

  useEffect(() => {
    window.scrollTo(0, 0);
    initFaviconManager();
  }, []);

  return (
    <>
      <ConsentManager
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
        locale={locale}
      />
      <HeaderNavigation
        data={data}
        pageContext={pageContext}
        pathname={location.pathname}
      />
      {nonNullishBlocks?.map((block, index) => (
        <Fragment key={index}>
          <BlockFactory index={index} block={block} locale={locale} />
        </Fragment>
      ))}
      <FooterNavigation data={data} pageContext={pageContext} />
    </>
  );
};

export const Head = PageHead;

export const query = graphql`
  query pageQuery($locale: String) {
    allCmsLocale {
      nodes {
        code
        name
        isDefault
        id
      }
    }
    allSitePage {
      nodes {
        path
        pageContext
      }
    }
    allStrapiGlobal(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          siteName
          locale
          id
          favicon {
            alternativeText
            caption
            width
            height
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          defaultPreviewImage {
            alternativeText
            caption
            width
            height
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          footer {
            Copyright
            languageLabel
            socialNetworksLabel
            app_stores {
              Name
              Url
              locale
              Badge {
                alternativeText
                caption
                width
                height
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 250
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            social_networks {
              AccountID
              Active
              Name
              ShowOnFooter
              Url
              Icon {
                alternativeText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 30
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              IconDark {
                alternativeText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 30
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          darkLogo {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          lightLogo {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    allMainNav(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        type
        locale
        id
        external
        menuAttached
        beta
        order
        path
        strapi_id
        uiRouterKey
      }
    }
    allFooterNav(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        type
        locale
        id
        external
        menuAttached
        order
        path
        strapi_id
        uiRouterKey
        subItems {
          external
          id
          menuAttached
          order
          path
          title
          type
          uiRouterKey
          related {
            FooterTemplate
            PageTemplate
            Slug
            Title
            id
          }
        }
      }
    }
    allStrapiDefaultSeo(filter: { locale: { eq: $locale } }) {
      nodes {
        locale
        SEO {
          author
          canonicalURL
          keywords
          metaDescription
          metaRobots
          metaTitle
          metaSocial {
            id
            content
            propertyOrName
            socialNetwork
          }
          metaViewport
          metaImage {
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;

export const getServerData = buildGetServerData(
  [withAuthGuard, withLanguageBasedRedirect],
  () => {
    return {
      status: 200,
      props: {},
    };
  },
);

export default DynamicLandingPage;
