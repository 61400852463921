// extracted by mini-css-extract-plugin
export var active = "LanguagePicker-module--active--c9e43";
export var current_language = "LanguagePicker-module--current_language--cd595";
export var hidden = "LanguagePicker-module--hidden--15bd8";
export var icon = "LanguagePicker-module--icon--ab874";
export var label_image = "LanguagePicker-module--label_image--e46b2";
export var language_list = "LanguagePicker-module--language_list--44a66";
export var language_list_item = "LanguagePicker-module--language_list_item--9a297";
export var opened = "LanguagePicker-module--opened--f566b";
export var position_bottom = "LanguagePicker-module--position_bottom--7135e";
export var position_top = "LanguagePicker-module--position_top--4f056";
export var preview = "LanguagePicker-module--preview--e89a0";
export var wrapper = "LanguagePicker-module--wrapper--91850";