import React, { FC } from 'react';
import { Nullish } from '@getquin/ts-helpers';
import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import { Media } from '@/components/Media';
import { ResolutionContent } from '@/components/ResolutionContent';
import { StoreButton } from '@/components/StoreButton';

import { HeroProps } from '../@types';
import { MobileButtonVariant } from '../constants';
import { HeroButtons } from './HeroButtons';
import * as styles from './HeroMedia.module.scss';

interface HeroMediaProps {
  beforeContent?: React.ReactNode;
  locale: string;
  media: Nullish<HeroProps['block']['media']>;
  mediaClassName?: string;
  mediaLoading?: 'eager' | 'lazy';
  mediaMobile: Nullish<HeroProps['block']['mediaMobile']>;
  mediaWrapperClassName?: string;
  mobileButtonVariant: MobileButtonVariant;
  primaryButton: Nullish<Queries.ButtonFragment>;
  secondaryButton: Nullish<Queries.ButtonFragment>;
}

export const HeroMedia: FC<HeroMediaProps> = ({
  beforeContent,
  locale,
  media,
  mediaClassName,
  mediaLoading,
  mediaMobile,
  mediaWrapperClassName,
  mobileButtonVariant,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <div className={cn(styles.media_wrapper, mediaWrapperClassName)}>
      {beforeContent}
      <StaticImage
        className={styles.gradient_container}
        imgClassName={styles.gradient}
        src="../../../../../../static/hero/bg.png"
        alt=""
        objectFit="fill"
      ></StaticImage>

      <div className={cn(styles.media, mediaClassName)}>
        <ResolutionContent
          defaultContent={
            media && <Media media={media} loading={mediaLoading} />
          }
          mobileContent={
            mediaMobile && <Media media={mediaMobile} loading={mediaLoading} />
          }
        />
      </div>

      {primaryButton?.url && (
        <div className={cn(styles.dowload_button_wrapper, styles.mobile_only)}>
          {mobileButtonVariant === MobileButtonVariant.DOWNLOAD && (
            <StoreButton
              dowloadUrl={primaryButton?.url}
              analytics={primaryButton?.analytics}
              hrefLang={locale}
            />
          )}
          {mobileButtonVariant === MobileButtonVariant.PRIMARY_SECONDARY && (
            <HeroButtons
              locale={locale}
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
            />
          )}
        </div>
      )}
    </div>
  );
};
