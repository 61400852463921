import React, { CSSProperties, FC, ReactNode } from 'react';

import { FeatureTextBlock } from '@/components/FeatureTextBlock';

import { BlockProps } from '../../@types';
import * as styles from './FeatureWithMediaAside.module.scss';

type FeatureWithMediaAsideProps =
  BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_FEATURE_WITH_MEDIA_ASIDE'>;

type FeatureWithMediaAsideContentProps = {
  title: FeatureWithMediaAsideProps['block']['featureTitle'];
  label: FeatureWithMediaAsideProps['block']['label'];
  labelIcon: FeatureWithMediaAsideProps['block']['labelIcon'];
  description: FeatureWithMediaAsideProps['block']['description'];
  media: ReactNode;
  mediaMaxWidth?: FeatureWithMediaAsideProps['block']['mediaMaxWidth'];
  cta?: ReactNode;
};

const DEFAULT_MAX_WIDTH = 500;
export const FeatureWithMediaAsideContent: FC<
  FeatureWithMediaAsideContentProps
> = ({ label, media, mediaMaxWidth, title, description, labelIcon, cta }) => {
  const mediaStyle = {
    '--media-max-width': `${mediaMaxWidth || DEFAULT_MAX_WIDTH}px`,
  } as CSSProperties;

  return (
    <>
      <FeatureTextBlock
        className={styles.content_column}
        label={label}
        title={title}
        labelIcon={labelIcon}
        description={description}
      >
        {cta}
      </FeatureTextBlock>
      <div className={styles.media_column} style={mediaStyle}>
        {media}
      </div>
    </>
  );
};
