import React, { FC } from 'react';
import { Nullish } from '@getquin/ts-helpers';
import cn from 'classnames';

import { Paper, Typography } from '@/ui-kit/web';

import { Button } from '@/components/Button';
import { LocaleLink } from '@/components/LocaleLink';
import { StoreButton } from '@/components/StoreButton';

import { normalizePath, pathnameToPath } from '@/utils/path';

import { LoginButton } from './AuthButtons';
import * as styles from './NavigationList.module.scss';

interface NavigationListProps {
  locale: string;
  navigationItems: Queries.pageQueryAndPageQueryQuery['allMainNav']['nodes'];
  pathname: string;
}
export const NavigationList: FC<NavigationListProps> = ({
  locale,
  navigationItems,
  pathname,
}) => {
  return (
    <>
      {navigationItems.map((item) => {
        if (!item.path) return null;
        const active =
          normalizePath(item.path) ===
          normalizePath(pathnameToPath(pathname, locale));

        return (
          <Button
            component={LocaleLink}
            external={item.external}
            href={item.path}
            hrefLang={locale}
            key={item.id}
            type="plaintext"
          >
            <Typography
              color={active ? 'primary' : 'secondary'}
              size="medium"
              weight="regular"
            >
              {item.title}
            </Typography>
          </Button>
        );
      })}
    </>
  );
};

interface NavigationListMobileProps {
  className?: string;
  locale: string;
  navigationItems: Queries.pageQueryAndPageQueryQuery['allMainNav']['nodes'];
  slug: Nullish<string>;
}

export const NavigationListMobile: FC<NavigationListMobileProps> = ({
  className,
  navigationItems,
  locale,
  slug,
}) => {
  return (
    <Paper className={cn(styles.mobile, className)}>
      <ul className={styles.list}>
        {navigationItems.map((item) => {
          if (!item.path) return null;

          return (
            <li key={item.id}>
              <Typography.Link
                className={styles.item}
                component={LocaleLink}
                external={item.external}
                hrefLang={locale}
                href={item.path}
                color="primary"
                size="large"
                weight="bold"
              >
                {item.title}
                <ChevronRightIcon />
              </Typography.Link>
            </li>
          );
        })}
      </ul>

      <StoreButton hrefLang={locale} />
      <LoginButton locale={locale} slug={slug} type="outlined" size="large" />
    </Paper>
  );
};

const ChevronRightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
