import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { Typography } from '@getquin/ui-kit';
import cn from 'classnames';

import ChevronDownOutlined from '@/ui-kit/icons/chevron-down-outlined.svg';

import * as styles from './Accordion.module.scss';
import { Collapser } from './Collapser';
import { AccordionContext } from './Context';

interface AccordionItemProps {
  eventKey: string;
  title: string;
}

export const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = memo(
  ({ children, eventKey, title }) => {
    const { activeEventKey, animationDuration, onSelect } =
      useContext(AccordionContext);

    const handleClick = useCallback(
      () => onSelect(eventKey),
      [eventKey, onSelect],
    );

    const selected = activeEventKey.includes(eventKey);

    return (
      <div
        className={cn(styles.accordion_item, { [styles.selected]: selected })}
      >
        <div
          className={styles.accordion_item_header}
          onClick={handleClick}
          role="button"
        >
          <Typography.Headline component="h3" size="small">
            {title}
          </Typography.Headline>
          <ChevronDownOutlined
            className={styles.accordion_item_header_icon}
            height={24}
            width={24}
          />
        </div>

        <Collapser
          className={styles.accordion_item_body}
          duration={animationDuration}
          expanded={selected}
        >
          {children}
        </Collapser>
      </div>
    );
  },
);
