import React, { FC, ReactNode } from 'react';
import { Nullable } from '@getquin/ts-helpers';
import cn from 'classnames';

import { createAllClassesBuilders } from '@/ui-kit/web';

import { BlockGrid } from '../../core/BlockGrid';
import { MediaPosition } from './@types';
import { MEDIA_POSITIONS } from './constants';
import * as styles from './FeatureWithMediaAside.module.scss';

const classesBuilder = createAllClassesBuilders(styles, {
  media_position: MEDIA_POSITIONS,
});

interface FeatureWithMediaAsideContainer {
  mediaPosition: Nullable<string>;
  gridClassName?: string;
  children: ReactNode;
  beforeContent?: ReactNode;
  afterContent?: ReactNode;
}

export const FeatureWithMediaAsideContainer: FC<
  FeatureWithMediaAsideContainer
> = ({
  afterContent,
  beforeContent,
  children,
  gridClassName,
  mediaPosition,
}) => {
  const position = (mediaPosition ?? 'right') as MediaPosition;

  return (
    <BlockGrid
      afterContent={afterContent}
      beforeContent={beforeContent}
      gridClassName={gridClassName}
      className={cn(styles.container, classesBuilder.media_position(position))}
    >
      {children}
    </BlockGrid>
  );
};
