import React, { FC } from 'react';
import { Nullish } from '@getquin/ts-helpers';

import { Typography } from '@/ui-kit/web';

import { MainHeadline } from '@/components/MainHeadline';
import { Richtext } from '@/components/Markdown';

import { BlockGrid } from '@/features/blocks/core/BlockGrid';

import { HeroProps } from '../@types';
import { HeroButtons } from './HeroButtons';
import * as styles from './HeroInformation.module.scss';
import { Kpis } from './Kpis';

interface HeroInformationProps {
  headlineComponent?: 'h1' | 'h2';
  kpis?: HeroProps['block']['kpis'];
  label?: Nullish<string>;
  locale: string;
  primaryButton: Nullish<Queries.ButtonFragment>;
  secondaryButton?: Nullish<Queries.ButtonFragment>;
  subtitle: Nullish<HeroProps['block']['subtitle']>;
  title: Nullish<HeroProps['block']['title']>;
}

export const HeroInformation: FC<HeroInformationProps> = ({
  headlineComponent = 'h2',
  kpis,
  label,
  locale,
  primaryButton,
  secondaryButton,
  subtitle,
  title,
}) => {
  return (
    <BlockGrid className={styles.container} transparent>
      <div className={styles.information}>
        {kpis && <Kpis kpis={kpis} />}

        {label && (
          <Typography.Headline
            align="center"
            color="secondary"
            component="p"
            size="medium"
          >
            {label}
          </Typography.Headline>
        )}

        <div className={styles.title_wrapper}>
          {title && (
            <MainHeadline align="center" component={headlineComponent}>
              {title}
            </MainHeadline>
          )}

          {subtitle && (
            <Typography align="center" color="secondary" size="large">
              <Richtext richtext={subtitle} />
            </Typography>
          )}

          <div className={styles.desktop_only}>
            <HeroButtons
              locale={locale}
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
            />
          </div>
        </div>
      </div>
    </BlockGrid>
  );
};
