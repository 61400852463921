import React, { FC, useEffect, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { Nullable } from '@getquin/ts-helpers';

import { StoreType } from '@/constants/stores';

import { type TrackableButtonProps, TrackableButton } from '../Button';
import * as styles from './StoreButton.module.scss';

interface StoreButtonProps {
  analytics?: TrackableButtonProps['analytics'];
  className?: string;
  dowloadUrl?: string;
  hrefLang: string;
}

interface ComputeStoreProps {
  store: StoreType;
}
const getStoreLogoSrc = ({ store }: ComputeStoreProps) => {
  return `/store-logos/${store.toLowerCase()}-black.svg`;
};

export const StoreButton: FC<StoreButtonProps> = ({
  analytics,
  className,
  dowloadUrl = 'https://getquin.onelink.me/X3Ev',
  hrefLang,
}) => {
  const [store, setStore] = useState<Nullable<StoreType>>(null);

  useEffect(() => {
    setStore(isIOS || isMacOs ? StoreType.APPLE : StoreType.GOOGLE);
  }, []);
  if (!store) return null;

  return (
    <TrackableButton
      size="large"
      analytics={analytics}
      block
      href={dowloadUrl}
      className={className}
      hrefLang={hrefLang}
    >
      <img
        className={styles.store_logo}
        loading="lazy"
        src={getStoreLogoSrc({ store })}
        alt={`Dowload on the ${store} icon`}
      />
    </TrackableButton>
  );
};
