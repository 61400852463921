import React, { FC } from 'react';
import { Nullish } from '@getquin/ts-helpers';

import { ButtonLabel, TrackableButton } from '@/components/Button';

import * as styles from './HeroButtons.module.scss';

interface HeroButtonsProps {
  locale: string;
  primaryButton: Nullish<Queries.ButtonFragment>;
  secondaryButton?: Nullish<Queries.ButtonFragment>;
}
export const HeroButtons: FC<HeroButtonsProps> = ({
  locale,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <div className={styles.buttons}>
      {primaryButton?.url && (
        <TrackableButton
          analytics={primaryButton.analytics}
          href={primaryButton.url}
          hrefLang={locale}
          size="large"
        >
          <ButtonLabel label={primaryButton.label} />
        </TrackableButton>
      )}

      {secondaryButton?.url && (
        <TrackableButton
          analytics={secondaryButton.analytics}
          href={secondaryButton.url}
          hrefLang={locale}
          size="large"
          type="outlined"
        >
          <ButtonLabel label={secondaryButton.label} />
        </TrackableButton>
      )}
    </div>
  );
};
