import React, { CSSProperties, memo } from 'react';
import { Nullish } from '@getquin/ts-helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

export interface ImageProps {
  alt?: Nullish<string>;
  className?: string;
  fit?: CSSProperties['objectFit'];
  height?: Nullish<number>;
  loading?: 'eager' | 'lazy';
  localFile: Nullish<Queries.MediaFragment['localFile']>;
  position?: CSSProperties['objectPosition'];
  width?: Nullish<number>;
}

export const Image = memo(
  ({
    alt: externalAlt,
    className,
    fit = 'contain',
    height,
    loading,
    localFile,
    position = 'center',
    width,
  }: ImageProps) => {
    if (!localFile) return null;

    const alt = externalAlt ?? `${localFile.extension} file`;

    if (localFile.extension === 'svg' || localFile.extension === 'gif') {
      if (!localFile.publicURL) return null;
      return (
        <img
          className={className}
          width={width || undefined}
          height={height || undefined}
          src={localFile.publicURL}
          alt={alt}
        />
      );
    }

    const gatsbyImage = getImage(localFile as unknown as FileNode);
    if (!gatsbyImage) return null;

    return (
      <GatsbyImage
        loading={loading}
        objectFit={fit}
        objectPosition={position}
        className={className}
        image={gatsbyImage}
        alt={alt}
      />
    );
  },
);
