/**
 * Creates typeguard function that checks if input value belongs to the enum.
 * Supports both string and numeric enums.
 *
 * @param enumValue - enum to check values against
 */
export const createEnumTypeguard = <T extends Record<string, unknown>>(
  enumValue: T,
) => {
  /**
   * Checks only non-numeric keys.
   * Reason: we need to account for that numeric enums are transpiled to the key-value value-key objects
   *
   * @example
   * enum Foo {
   *  BAR
   * }
   *
   * // will be transpiled to equivalent of
   * const Foo = {
   *  "BAR": "0",
   *  "0": "BAR"
   * }
   */
  const nonNumericKeys = Object.keys(enumValue).filter((k) => !/^\d/.test(k));
  const values = nonNumericKeys.map((key) => enumValue[key]);
  const set = new Set(values);

  return (val: unknown): val is T[keyof T] => {
    return set.has(val);
  };
};
