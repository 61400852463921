import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import * as styles from './ResolutionContent.module.scss';

interface ResolutionContentProps {
  defaultContent: ReactNode;
  mobileContent: ReactNode;
}
export const ResolutionContent: FC<ResolutionContentProps> = ({
  defaultContent,
  mobileContent,
}) => {
  return (
    <>
      <div className={cn({ [styles.desktop_only]: mobileContent })}>
        {defaultContent}
      </div>

      {mobileContent && (
        <div className={styles.mobile_only}>{mobileContent}</div>
      )}
    </>
  );
};
