import React, { FC, memo, PropsWithChildren, useRef } from 'react';

import { useIsomorphicLayoutEffect } from '@/hooks/dom';

interface CollapserProps {
  className?: string;
  duration?: number | string;
  expanded: boolean;
}

export const Collapser: FC<PropsWithChildren<CollapserProps>> = memo(
  ({ children, className, duration = 300, expanded }) => {
    const wrapperElRef = useRef<HTMLDivElement | null>(null);
    const innerRef = useRef<HTMLDivElement | null>(null);

    useIsomorphicLayoutEffect(() => {
      if (!wrapperElRef.current || !innerRef.current) {
        return;
      }

      wrapperElRef.current.style.setProperty(
        'height',
        `${expanded ? innerRef.current.offsetHeight : 0}px`,
      );

      innerRef.current.style.setProperty(
        'transform',
        `translateY(${expanded ? 0 : innerRef.current.offsetHeight}px)`,
      );
    }, [expanded]);

    return (
      <div
        className={className}
        ref={wrapperElRef}
        style={{
          height: '0px',
          overflow: 'hidden',
          transitionDuration: `${duration}ms`,
          transitionProperty: 'height',
          transitionTimingFunction: 'ease',
        }}
      >
        <div
          ref={innerRef}
          style={{
            height: 'auto',
            transitionDuration: `${duration}ms`,
            transformOrigin: 'top',
            transitionProperty: 'transform',
            transitionTimingFunction: 'ease',
          }}
        >
          {children}
        </div>
      </div>
    );
  },
);
