import React, { FC } from 'react';
import { Nullable } from '@getquin/ts-helpers';

import { normalizePath } from '@/utils/path';

interface LocaleLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  hrefLang: string;
  external: Nullable<boolean>;
}

export const LocaleLink: FC<LocaleLinkProps> = ({
  href,
  hrefLang,
  external,
  ...rest
}) => {
  const prefix = hrefLang === 'en' ? '' : `/${hrefLang}`;
  const localeHref = normalizePath(`${prefix}/${href}`);
  return (
    <a
      rel="noreferrer"
      target={external ? '_blank' : '_self'}
      hrefLang={hrefLang}
      href={external ? href : localeHref}
      {...rest}
    />
  );
};
