import React, { FC } from 'react';

interface BurgerMenuIconProps {
  className?: string;
  onClick: VoidFunction;
}

export const BurgerMenuIcon: FC<BurgerMenuIconProps> = ({
  className,
  onClick,
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <line
      x1="2.5"
      y1="5"
      x2="21.5"
      y2="5"
      stroke="white"
      strokeLinecap="round"
    />
    <line
      x1="2.5"
      y1="12"
      x2="21.5"
      y2="12"
      stroke="white"
      strokeLinecap="round"
    />
    <line
      x1="2.5"
      y1="19"
      x2="21.5"
      y2="19"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
);
