import React, { FC, useCallback, useEffect, useRef } from 'react';

import { useOnScreen } from '@/hooks/intersection';

import { Image } from '../Image';
import { MediaProps } from './@types';
const VIDEO_EXTERNSIONS = ['mp4', 'mpeg', 'mov', 'webm'];

function getVideoMimeType(extension: string) {
  switch (extension.toLowerCase()) {
    case 'mp4':
      return 'video/mp4';
    case 'mpeg':
      return 'video/mpeg';
    case 'mov':
      return 'video/mp4;codecs=hvc1';
    case 'webm':
      return 'video/webm';
    default:
      return 'unknown';
  }
}

const Video: FC<MediaProps> = ({ className, fallback, media }) => {
  const playingRef = useRef(false);

  const videoRef = useOnScreen<HTMLVideoElement>(
    useCallback(({ isIntersecting, target }) => {
      if (!isIntersecting || playingRef.current) return;

      setTimeout(() => {
        playingRef.current = true;
        target.play();
      }, 0);
    }, []),
    { root: null, threshold: 0.5 },
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && videoRef.current) {
        videoRef.current.play();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <video
      autoPlay={false}
      className={className}
      controls={false}
      muted
      playsInline
      ref={videoRef}
    >
      {media.localFile?.publicURL && (
        <source
          src={media.localFile.publicURL}
          type={getVideoMimeType(media.localFile.extension)}
        />
      )}
      {fallback?.localFile?.publicURL && (
        <source
          src={fallback.localFile.publicURL}
          type={getVideoMimeType(fallback.localFile.extension)}
        />
      )}
    </video>
  );
};

export const Media: FC<MediaProps> = ({
  className,
  loading,
  media,
  fallback,
}) => {
  if (!media?.localFile) return null;

  if (VIDEO_EXTERNSIONS.includes(media.localFile.extension)) {
    return <Video className={className} media={media} fallback={fallback} />;
  }

  return (
    <Image
      alt={media.alternativeText || ''}
      className={className}
      loading={loading}
      localFile={media.localFile}
    />
  );
};
