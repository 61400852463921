import React, { FC } from 'react';
import Marquee from 'react-fast-marquee';

import { Typography } from '@/ui-kit/web';

import { Image } from '@/components/Image';

import { nullishItemListToItemList } from '@/utils/nullish';

import { BlockProps } from '../../@types';
import { BlockGrid } from '../../core/BlockGrid';
import * as styles from './PressLogos.module.scss';

type PressLogosProps = BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_PRESS_LOGOS'>;

export const PressLogos: FC<PressLogosProps> = ({ block }) => {
  const nonNullishList = nullishItemListToItemList(block.list);

  return (
    <BlockGrid className={styles.content}>
      <Typography.Headline component="span" size="medium" align="center">
        {block.logosTitle}
      </Typography.Headline>

      <div className={styles.wrapper}>
        <Marquee loop={0} speed={10} direction="left" pauseOnHover={false}>
          <div className={styles.list}>
            {nonNullishList.map(({ logo, id }) => (
              <Image key={id} localFile={logo?.localFile} {...logo} />
            ))}
          </div>
        </Marquee>
      </div>
    </BlockGrid>
  );
};
