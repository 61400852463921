import React, { memo, useMemo } from 'react';

import { PolymorphForwardRef } from '@/ui-kit/web';

import { TrackableButtonProps } from './@types';
import { Button } from './Button';
import { useButtonAnalytics } from './hooks';

export const TrackableButton = memo(
  ({ analytics, href, hrefLang, ...rest }) => {
    const [onClick, attributes] = useButtonAnalytics(analytics);

    const hrefWithLang = useMemo(() => {
      try {
        const url = new URL(href);
        url.searchParams.append('lang', hrefLang);

        return url.toString();
      } catch (error) {
        return href;
      }
    }, [href]);

    return (
      <Button
        {...rest}
        component="a"
        href={hrefWithLang}
        hrefLang={hrefLang}
        data-attributes={attributes}
        onClick={onClick}
        target="_blank"
      />
    );
  },
) as PolymorphForwardRef<TrackableButtonProps, 'a'>;
