import React, { FC, Fragment } from 'react';

import { Divider, Typography } from '@/ui-kit/web';

import { Markdown } from '@/components/Markdown';

import { nullishItemListToItemList } from '@/utils/nullish';

import { KpisProps } from '../@types';
import * as styles from './Kpis.module.scss';

export const Kpis: FC<KpisProps> = ({ kpis }) => {
  const markdowns = kpis?.items?.map(
    (item) => item?.text?.data?.childMarkdownRemark?.rawMarkdownBody,
  );
  const nonNullishMarkdowns = nullishItemListToItemList(markdowns);

  if (!nonNullishMarkdowns.length) return null;

  return (
    <div className={styles.kpis}>
      {nonNullishMarkdowns.map((markdownBody, index) => {
        return (
          <Fragment key={markdownBody}>
            {index !== 0 && (
              <Divider type="vertical" className={styles.divider} />
            )}

            <Markdown
              components={{
                p: ({ children }) => (
                  <Typography color="secondary" size="large">
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <Typography color="primary" size="large" weight="bold">
                    {children}
                  </Typography>
                ),
              }}
            >
              {markdownBody}
            </Markdown>
          </Fragment>
        );
      })}
    </div>
  );
};
