import React, { Fragment } from 'react';
import { HeadProps, Script } from 'gatsby';

import { languagueURLSlug, normalizePath } from '@/utils/path';

import { FONT_LINKS_FOR_PRELOAD } from '@/constants/fonts';

import { FACEBOOK_APP_ID } from '@/__legacy/const/seo';

import {
  getAlternatePagesList,
  getContentFromMetaSocial,
  getFAQStructuredData,
  getGraphLdSchema,
  getOrganizationStructuredData,
} from './utils';

export const PageHead = ({
  data,
  pageContext,
}: HeadProps<
  Queries.pageQueryAndPageQueryQuery,
  Queries.getPagesQuery['allStrapiLandingPageV2']['nodes'][number]
>) => {
  const defaultSeoNode = data.allStrapiDefaultSeo.nodes[0];
  const globalNode = data.allStrapiGlobal.edges[0]?.node;

  const seo = {
    metaImage: globalNode?.defaultPreviewImage,
    siteName: globalNode?.siteName,
    ...defaultSeoNode?.SEO,
    ...pageContext.SEO,
  };

  const domain = 'https://www.getquin.com';
  const locale = pageContext.locale || 'en';

  const canonicalUrlFallback = `${domain}${languagueURLSlug(locale)}${normalizePath(pageContext.Slug)}`;
  const canonicalURL = seo?.canonicalURL || canonicalUrlFallback;

  const alternate = getAlternatePagesList(
    pageContext.Slug,
    pageContext.slugGroup,
    data.allSitePage.nodes,
  );
  const title = seo?.metaTitle || undefined;
  const metaSocial = seo?.metaSocial;
  const metaRobots = seo?.metaRobots || 'all';
  const keywords = seo?.keywords;
  const image = seo?.metaImage?.localFile?.publicURL || undefined;
  const description = seo?.metaDescription;
  const author = seo?.author;

  const ogTitle = getContentFromMetaSocial('og:title', metaSocial) || title;
  const ogDescription =
    getContentFromMetaSocial('og:description', metaSocial) || description;
  const ogType = getContentFromMetaSocial('og:type', metaSocial) || 'website';
  const ogImage = getContentFromMetaSocial('og:image', metaSocial) || image;
  const ogUrl = getContentFromMetaSocial('og:url', metaSocial) || canonicalURL;

  const twitterCard =
    getContentFromMetaSocial('twitter:image', metaSocial) ||
    'summary_large_image';
  const twitterCreator =
    getContentFromMetaSocial('twitter:creator', metaSocial) || author;
  const twitterUrl =
    getContentFromMetaSocial('twitter:url', metaSocial) || canonicalURL;
  const twitterImage =
    getContentFromMetaSocial('twitter:image', metaSocial) || image;
  const twitterTitle =
    getContentFromMetaSocial('twitter:title', metaSocial) || title;
  const twitterDescription =
    getContentFromMetaSocial('twitter:description', metaSocial) || description;

  const fbAppId =
    getContentFromMetaSocial('fb:app_id', metaSocial) || FACEBOOK_APP_ID;

  const structuredData = seo?.structuredData?.internal.content;

  const faq = getFAQStructuredData({ data, domain, pageContext });
  const organization = getOrganizationStructuredData({
    data,
    domain,
    pageContext,
  });
  const graphLdSchema = getGraphLdSchema({ faq, organization });

  return (
    <>
      <title>{title}</title>
      <html lang={locale} data-color-scheme="dark" />
      {description && <meta name="description" content={description} />}

      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}

      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {twitterCreator && (
        <meta name="twitter:creator" content={twitterCreator} />
      )}
      {twitterUrl && <meta name="twitter:url" content={twitterUrl} />}
      {twitterImage && <meta property="twitter:image" content={twitterImage} />}
      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && (
        <meta name="twitter:description" content={twitterDescription} />
      )}

      {fbAppId && <meta property="fb:app_id" content={fbAppId} />}

      {keywords && <meta name="keywords" content={keywords} />}

      <meta name="robots" content={metaRobots}></meta>

      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="coinzilla" content="d6adb26be378715975e8aea3c3aab60e" />

      {/* <!-- Chrome, Firefox OS and Opera --> */}
      <meta name="theme-color" content="#000000" />
      {/* <!-- Windows Phone --> */}
      <meta name="msapplication-navbutton-color" content="#000000" />
      {/* <!-- iOS Safari --> */}
      <meta name="apple-mobile-web-app-status-bar-style" content="#000000" />

      <link key="canonical" rel="canonical" href={canonicalURL} />
      <link
        key="segment_preconnect"
        rel="preconnect"
        href="https://cdn.segment.com"
      />

      {FONT_LINKS_FOR_PRELOAD.map((item) => (
        <link key={item.href} {...item} />
      ))}

      {alternate?.map((route) => {
        return (
          <Fragment key={`${route.path}-${route.locale}-${route.xDefault}`}>
            {!!route.xDefault && (
              <link
                rel="alternate"
                href={`${domain}${route.path}`}
                hrefLang="x-default"
              />
            )}
            <link
              rel="alternate"
              href={`${domain}${route.path}`}
              hrefLang={route.locale}
            />
          </Fragment>
        );
      })}

      {structuredData && (
        <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: structuredData }}
        />
      )}

      {graphLdSchema && (
        <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(graphLdSchema) }}
        />
      )}

      <Script src="/smart-link.js" defer />
    </>
  );
};
