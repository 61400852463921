import React, { FC } from 'react';
import cn from 'classnames';

import { CompanyLogo, Typography } from '@/ui-kit/web';

import { LanguagePicker } from '@/components/LanguagePicker';
import { LocaleLink } from '@/components/LocaleLink';

import { getAlternatePagesList } from '@/features/seo/utils';

import * as styles from './FooterNavigation.module.scss';
import { BaseSection, LinksSection } from './Section';
import { SocialNetworkItem } from './SocialNetworkItem';
interface FooterNavigationProps {
  data: Queries.pageQueryAndPageQueryQuery;
  pageContext: Queries.getPagesQuery['allStrapiLandingPageV2']['nodes'][number];
}
export const FooterNavigation: FC<FooterNavigationProps> = ({
  data,
  pageContext,
}) => {
  const locale = pageContext.locale || 'en';
  const alternate = getAlternatePagesList(
    pageContext.Slug,
    pageContext.slugGroup,
    data.allSitePage.nodes,
  );

  const footerNavigation = data.allFooterNav.nodes;
  const globalFooter = data.allStrapiGlobal.edges?.[0]?.node?.footer;

  const socialNetworksLabel = globalFooter?.socialNetworksLabel;
  const socialNetworks = globalFooter?.social_networks;

  const languageLabel = globalFooter?.languageLabel;

  return (
    <footer className={styles.page_grid}>
      <div className={styles.logo}>
        <Typography.Link
          component={LocaleLink}
          external={false}
          href="/"
          hrefLang={locale}
        >
          <CompanyLogo />
        </Typography.Link>
      </div>

      {footerNavigation.map((section) => (
        <div key={section.id} className={styles.section}>
          <LinksSection
            items={section.subItems}
            locale={locale}
            title={section.title}
          />
        </div>
      ))}
      <div className={cn(styles.section, styles.social_section)}>
        {socialNetworks && (
          <BaseSection
            title={socialNetworksLabel}
            contentClassName={styles.social_networks}
          >
            {socialNetworks?.map((item) => {
              if (!item?.IconDark?.localFile || !item.Url) return null;

              return (
                <SocialNetworkItem
                  key={item?.Url}
                  icon={item.IconDark.localFile}
                  href={item.Url}
                  alt={`${item.IconDark.alternativeText} icon`}
                />
              );
            })}
          </BaseSection>
        )}

        <BaseSection
          title={languageLabel}
          contentClassName={styles.language_picker}
        >
          <LanguagePicker
            current={locale}
            alternateList={alternate}
            position="top"
          />
        </BaseSection>
      </div>
    </footer>
  );
};
