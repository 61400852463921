import React, { FC } from 'react';
import { Nullish } from '@getquin/ts-helpers';

import { TrackableButton, TrackableButtonProps } from '@/components/Button';

import {
  Analytics,
  mapObjectToButtonAnalyticsAttributes,
} from '@/services/analytics';

import * as styles from './AuthButtons.module.scss';

interface AuthButtonsProps {
  locale: string;
  slug: Nullish<string>;
}

const getAnalyticsAttributes = (slug: Nullish<string>) => {
  return {
    event_name: Analytics.EVENTS.CTA_CLICKED,
    event_attributes: mapObjectToButtonAnalyticsAttributes({
      cta_location: 'navbar',
      page_type: slug,
    }),
  };
};

export const SignUpButton: FC<
  AuthButtonsProps & { type: TrackableButtonProps['type'] }
> = ({ locale, slug, type }) => {
  return (
    <TrackableButton
      analytics={getAnalyticsAttributes(slug)}
      href="https://app.getquin.com/registration"
      hrefLang={locale}
      size="small"
      type={type}
    >
      {locale == 'de' ? 'Registrieren' : 'Sign Up'}
    </TrackableButton>
  );
};

export const LoginButton: FC<
  AuthButtonsProps & {
    type: TrackableButtonProps['type'];
    size?: TrackableButtonProps['size'];
  }
> = ({ locale, slug, type, size = 'small' }) => {
  return (
    <TrackableButton
      analytics={getAnalyticsAttributes(slug)}
      size={size}
      href="https://app.getquin.com/login"
      hrefLang={locale}
      type={type}
    >
      {locale == 'de' ? 'Anmelden' : 'Login'}
    </TrackableButton>
  );
};

export const AuthButtons: FC<AuthButtonsProps> = ({ locale, slug }) => {
  return (
    <div className={styles.buttons}>
      <SignUpButton locale={locale} slug={slug} type="filled" />
      <LoginButton locale={locale} slug={slug} type="outlined" />
    </div>
  );
};
