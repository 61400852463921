import React, { FC } from 'react';

import { Paper, Typography } from '@/ui-kit/web';

import { Richtext, RichtextProps } from '@/components/Markdown';

import * as styles from './Testimonials.module.scss';

interface TestimonialProps {
  name: null | string;
  storeName: null | string;
  text: RichtextProps['richtext'];
  title: null | string;
}

export const Testimonial: FC<TestimonialProps> = ({
  name,
  storeName,
  text,
  title,
}) => {
  return (
    <div className={styles.testimonial}>
      <Paper className={styles.testimonial__text_container}>
        {title && (
          <Typography.Headline size="medium" align="left" component="p">
            {title}
          </Typography.Headline>
        )}

        <Typography align="left">
          <Richtext richtext={text} />
        </Typography>

        <div className={styles.testimonial__user}>
          <Typography size="medium" weight="bold">
            {name}
          </Typography>

          <Typography size="medium" color="secondary">
            {storeName}
          </Typography>
        </div>
      </Paper>
    </div>
  );
};
