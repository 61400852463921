import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Typography } from '@/ui-kit/web';

import { Image } from '../Image';
import { MainHeadline } from '../MainHeadline';
import { Richtext, RichtextProps } from '../Markdown';
import { MediaProps } from '../Media/@types';
import * as styles from './FeatureTextBlock.module.scss';

interface FeatureTextBlockProps {
  label: null | string;
  title: null | string;
  description: null | RichtextProps['richtext'];
  labelIcon: null | MediaProps['media'];
  children?: ReactNode;
  className?: string;
  align?: 'center' | 'left';
}

export const FeatureTextBlock: FC<FeatureTextBlockProps> = ({
  label,
  title,
  description,
  labelIcon,
  children,
  className,
  align = 'left',
}) => {
  return (
    <div
      className={cn(
        styles.container,
        { [styles.container_center]: align === 'center' },
        className,
      )}
    >
      <div className={styles.label_wrapper}>
        <Image
          width={40}
          height={40}
          localFile={labelIcon?.localFile}
          alt={labelIcon?.alternativeText}
        />
        <Typography.Headline component="span" size="medium">
          {label}
        </Typography.Headline>
      </div>

      <div className={styles.title_wrapper}>
        <MainHeadline align={align} className={styles.text}>
          {title}
        </MainHeadline>
      </div>

      <div className={styles.description_wrapper}>
        {description && (
          <Typography
            color="secondary"
            align={align}
            size="large"
            className={styles.text}
          >
            <Richtext richtext={description} />
          </Typography>
        )}
      </div>

      {children}
    </div>
  );
};
