import React from 'react';
import cn from 'classnames';

import { Typography } from '@/ui-kit/web';

import * as styles from './MainHeadline.module.scss';

export const MainHeadline: typeof Typography.Headline = ({
  children,
  className,
  ...props
}) => (
  <Typography.Headline
    component="h2"
    {...props}
    className={cn(styles.main_headline, className)}
  >
    {children}
  </Typography.Headline>
);
