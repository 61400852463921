import React, { FC } from 'react';
import { sortBy } from 'lodash';

import { Typography } from '@/ui-kit/web';

import { Accordion } from '@/components/Accordion';
import { MainHeadline } from '@/components/MainHeadline';

import { nullishItemListToItemList } from '@/utils/nullish';

import { BlockProps } from '../../@types';
import { BlockGrid } from '../../core/BlockGrid';
import * as styles from './FAQ.module.scss';

type FAQProps = BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_FAQ'>;

export const FAQ: FC<FAQProps> = ({ block }) => {
  if (!block.QA) return null;

  const nonNullishQA = nullishItemListToItemList(block.QA);
  const sortedQA = sortBy(nonNullishQA, 'Order');

  return (
    <BlockGrid className={styles.container}>
      <MainHeadline>FAQs</MainHeadline>

      <Accordion animationDuration={100}>
        {sortedQA.map((qa, index) => {
          if (!qa.Question || !qa.Answer) return null;
          return (
            <Accordion.Item
              key={index}
              eventKey={`${index}`}
              title={qa.Question}
            >
              <Typography color="secondary" size="medium">
                {qa.Answer.data?.Answer}
              </Typography>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </BlockGrid>
  );
};
