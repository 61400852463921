import { Nullable } from '@getquin/ts-helpers';

export function normalizePath(inputPath: Nullable<string>): string {
  if (!inputPath || inputPath === '/') return '/';
  // Remove leading, trailing, and duplicate slashes
  const trimmedPath = inputPath.replace(/\/+/g, '/').replace(/\/$/, '');

  // If after trimming the path is empty, return "/"
  if (trimmedPath === '') return '/';

  // Ensure the path starts with a single slash
  const normalizedPath = trimmedPath.startsWith('/')
    ? trimmedPath
    : `/${trimmedPath}`;

  // Add trailing slash
  return `${normalizedPath}/`;
}

/**
 * Returns a URL slug for the given language.
 * @param {string} lang - The language to generate the URL slug for.
 * @returns {string} - An empty string if the language is 'en', or '/{lang}' otherwise, where {lang} is the language code.
 */
export const languagueURLSlug = (lang: Nullable<string>) => {
  return !lang || lang === 'en' ? '' : `/${lang}`;
};

export const pathnameToPath = (pathname: string, locale: string) => {
  const trimmedPathname = pathname.trim();
  if (locale === 'en') {
    return trimmedPathname;
  }
  return trimmedPathname.replace(`/${locale}`, '');
};
