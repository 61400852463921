import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';

import * as styles from './BlockGrid.module.scss';

interface BlockGridProps {
  afterContent?: ReactNode;
  beforeContent?: ReactNode;
  className?: string;
  gridClassName?: string;
  style?: CSSProperties;
  transparent?: boolean;
}
export const BlockGrid: FC<PropsWithChildren<BlockGridProps>> = ({
  afterContent,
  beforeContent,
  children,
  className,
  gridClassName,
  style,
  transparent,
}) => {
  return (
    <div
      className={cn(styles.page_grid, gridClassName, {
        [styles.transparent]: transparent,
      })}
    >
      {beforeContent}
      <div className={cn(styles.container, className)} style={style}>
        {children}
      </div>
      {afterContent}
    </div>
  );
};
