import React, { FC } from 'react';

import { PageBlock } from './@types';
import { DataSecurityWithMediaAside } from './blocks/DataSecurityWithMediaAside';
import { DividerBlock } from './blocks/Divider';
import { FAQ } from './blocks/FAQ';
import { Feature } from './blocks/Feature';
import { FeatureWithMediaAside } from './blocks/FeatureWithMediaAside';
import { Hero } from './blocks/Hero';
import { HeroSecurity } from './blocks/HeroSecurity';
import { PressLogos } from './blocks/PressLogos';
import { Testimonials } from './blocks/Testimonials';

interface BlockFactoryProps {
  block: PageBlock;
  index: number;
  locale: string;
}

/**
 * IMPORTANT NOTE
 * each block has its own margin
 * it was really hard to find the logic in design
 * so I decided to add margins to each block
 */
export const BlockFactory: FC<BlockFactoryProps> = ({
  block,
  index,
  locale,
}) => {
  if (!block) return null;

  switch (block.__typename) {
    case 'STRAPI__COMPONENT_BLOCKS_V_2_FAQ':
      return <FAQ block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_BLOCKS_V_2_HERO':
      return <Hero block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_BLOCKS_V_2_PRESS_LOGOS':
      return <PressLogos block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_BLOCKS_V_2_FEATURE':
      return <Feature block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_BLOCKS_V_2_FEATURE_WITH_MEDIA_ASIDE':
      return (
        <FeatureWithMediaAside block={block} index={index} locale={locale} />
      );

    case 'STRAPI__COMPONENT_BLOCKS_V_2_DATA_SECURITY_WITH_MEDIA_ASIDE':
      return (
        <DataSecurityWithMediaAside
          block={block}
          index={index}
          locale={locale}
        />
      );

    case 'STRAPI__COMPONENT_BLOCKS_V_2_TESTIMONIALS':
      return <Testimonials block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_UI_KIT_DIVIDER':
      return <DividerBlock block={block} index={index} locale={locale} />;

    case 'STRAPI__COMPONENT_BLOCKS_V_2_HERO_SECURITY':
      return <HeroSecurity block={block} index={index} locale={locale} />;

    default:
      return null;
  }
};
