import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { BlockProps } from '../../@types';
import {
  FeatureWithMediaAsideContainer,
  FeatureWithMediaAsideContent,
} from '../FeatureWithMediaAside';
import * as styles from './DataSecurityWithMediaAside.module.scss';

type BaseFeatureProps =
  BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_DATA_SECURITY_WITH_MEDIA_ASIDE'>;

export const DataSecurityWithMediaAside: FC<BaseFeatureProps> = ({ block }) => {
  return (
    <FeatureWithMediaAsideContainer
      mediaPosition="left"
      gridClassName={styles.grid}
      beforeContent={
        <picture>
          <source media="(max-width: 768px)" srcSet="/security/bg-mobile.png" />
          <img className={styles.bg} src="/security/bg.png" alt="" />
        </picture>
      }
    >
      <FeatureWithMediaAsideContent
        {...block}
        media={
          <StaticImage
            src="../../../../../static/security/lock.png"
            alt="lock image"
          />
        }
        title={block.blockTitle}
      />
    </FeatureWithMediaAsideContainer>
  );
};
