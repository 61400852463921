import React from 'react';
import cn from 'classnames';

import { Button as UiKitButton } from '@/ui-kit/web';

import * as styles from './Button.module.scss';

export const Button: typeof UiKitButton = ({ className, type, ...rest }) => {
  return (
    <UiKitButton
      {...rest}
      type={type}
      // outlined button in the ui-kit has a background
      // which should be removed
      className={cn(className, { [styles.outlined]: type === 'outlined' })}
    />
  );
};
