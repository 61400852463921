import type { Nullish, Option } from '@getquin/ts-helpers';

export function nullishItemListToItemList<T>(
  list: Nullish<ReadonlyArray<Nullish<T>>>,
  fallbackList: T[] = [],
): T[] {
  if (!list) {
    return fallbackList;
  }

  return list.filter(Boolean) as T[];
}

export function nullishItemToItem<T>(item: Nullish<T>, fallbackItem: T): T {
  return item || fallbackItem;
}

export function nullishItemToOptionalItem<T>(item: Nullish<T>): Option<T> {
  return item ?? undefined;
}
