import React, { FC } from 'react';

import ArrowRightIcon from '@/ui-kit/icons/arrow-right-outlined.svg';

import { ButtonLabel, TrackableButton } from '@/components/Button';
import { Media } from '@/components/Media';

import { BlockProps } from '../../@types';
import { FeatureWithMediaAsideContainer } from './Container';
import { FeatureWithMediaAsideContent } from './Content';
import * as styles from './FeatureWithMediaAside.module.scss';

type FeatureWithMediaAsideProps =
  BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_FEATURE_WITH_MEDIA_ASIDE'>;

export const FeatureWithMediaAside: FC<FeatureWithMediaAsideProps> = ({
  block,
  locale,
}) => {
  const cta = !!block.button?.url && (
    <TrackableButton
      analytics={block.button.analytics}
      href={block.button.url}
      hrefLang={locale}
      size="large"
      iconEnd={<ArrowRightIcon />}
    >
      <ButtonLabel label={block.button.label} />
    </TrackableButton>
  );

  const media = block.media && (
    <Media
      className={styles.media}
      fallback={block.fallbackMedia}
      media={block.media}
    />
  );

  return (
    <FeatureWithMediaAsideContainer mediaPosition={block.mediaPosition}>
      <FeatureWithMediaAsideContent
        {...block}
        cta={cta}
        media={media}
        title={block.featureTitle}
      />
    </FeatureWithMediaAsideContainer>
  );
};
