import React, { FC } from 'react';

import { Divider } from '@/ui-kit/web';

import { BlockProps } from '../../@types';
import { BlockGrid } from '../../core/BlockGrid';

type DividerProps = BlockProps<'STRAPI__COMPONENT_UI_KIT_DIVIDER'>;

export const DividerBlock: FC<DividerProps> = () => {
  return (
    <BlockGrid>
      <Divider />
    </BlockGrid>
  );
};
