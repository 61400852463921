interface FontLinkForPreload {
  rel: string;
  href: string;
  as: string;
  crossOrigin: 'anonymous' | 'use-credentials' | '' | undefined;
  type: string;
}

const getFontLink = ({
  type,
  href,
}: Pick<FontLinkForPreload, 'href' | 'type'>): FontLinkForPreload => ({
  href,
  type,
  rel: 'preload',
  as: 'font',
  crossOrigin: 'anonymous',
});

const FONT_PATHS = [
  '/fonts/Unica77LLWeb-Bold',
  '/fonts/Unica77LLWeb-Regular',
  '/fonts/Unica77MonoLLWeb-Regular',
];

export const FONT_LINKS_FOR_PRELOAD = FONT_PATHS.reduce<FontLinkForPreload[]>(
  (acc, path) => {
    acc.push(getFontLink({ type: 'font/woff', href: `${path}.woff` }));
    acc.push(getFontLink({ type: 'font/woff2', href: `${path}.woff2` }));
    return acc;
  },
  [],
);
