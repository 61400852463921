import { useEffect, useRef } from 'react';

const DEFAULT_OPTIONS: IntersectionObserverInit = { rootMargin: '0px' };
type Entry<T> = Omit<IntersectionObserverEntry, 'target'> & { target: T };

export const useOnScreen = <T extends Element>(
  callback: (entry: Entry<T>) => void,
  options: IntersectionObserverInit = DEFAULT_OPTIONS,
) => {
  const ref = useRef<T>(null);
  const { root, rootMargin, threshold } = options;

  useEffect(() => {
    const element = ref.current;
    const computedOptions = { rootMargin, root, threshold };

    const observer = new IntersectionObserver(
      ([entry]) => callback(entry as Entry<T>),
      computedOptions,
    );

    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [callback, root, rootMargin, threshold]);

  return ref;
};
