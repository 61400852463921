import { createContext } from 'react';

/**
 * Accordion context.
 */

export type AccordionEventKey = string | string[];

export interface AccordionContextValue {
  activeEventKey: string[];
  animationDuration: number;
  onSelect: (eventKey: string) => void;
}

export const AccordionContext = createContext<AccordionContextValue>({
  activeEventKey: [],
  animationDuration: 300,
  onSelect: () => {},
});
