// extracted by mini-css-extract-plugin
export var carousel = "Testimonials-module--carousel--9abe7";
export var container = "Testimonials-module--container--506ce";
export var list = "Testimonials-module--list--01e34";
export var list__desktop = "Testimonials-module--list__desktop--54204";
export var list__mobile = "Testimonials-module--list__mobile--b855f";
export var rating = "Testimonials-module--rating--20b34";
export var rating_content = "Testimonials-module--rating_content--94888";
export var right_wheat = "Testimonials-module--right_wheat--fcf71";
export var star = "Testimonials-module--star--7afff";
export var stars = "Testimonials-module--stars--1743b";
export var testimonial = "Testimonials-module--testimonial--eb5f1";
export var testimonial__text_container = "Testimonials-module--testimonial__text_container--b929f";
export var testimonial__user = "Testimonials-module--testimonial__user--eede1";