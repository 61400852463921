import React, { CSSProperties, FC } from 'react';

import ArrowRightIcon from '@/ui-kit/icons/arrow-right-outlined.svg';

import { ButtonLabel, TrackableButton } from '@/components/Button';
import { FeatureTextBlock } from '@/components/FeatureTextBlock';
import { Media } from '@/components/Media';
import { ResolutionContent } from '@/components/ResolutionContent';

import { BlockProps } from '../../@types';
import { BlockGrid } from '../../core/BlockGrid';
import * as styles from './Feature.module.scss';

type FeatureProps = BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_FEATURE'>;

export const Feature: FC<FeatureProps> = ({ block, locale }) => {
  const mediaStyle = {
    '--media-max-width': `${block.mediaMaxWidth}px` || 'unset',
  } as CSSProperties;

  const cta = !!block.button?.url && (
    <TrackableButton
      analytics={block.button.analytics}
      href={block.button.url}
      hrefLang={locale}
      size="large"
      iconEnd={<ArrowRightIcon />}
    >
      <ButtonLabel label={block.button.label} />
    </TrackableButton>
  );

  return (
    <BlockGrid
      className={styles.container}
      // afterContent={<img className={styles.bg} src="./feature/bg.png" alt="" />}
    >
      <FeatureTextBlock
        className={styles.text_block}
        title={block.title}
        label={block.label}
        labelIcon={block.labelIcon}
        description={block.description}
        align="center"
      >
        <ResolutionContent defaultContent={null} mobileContent={cta} />
      </FeatureTextBlock>

      {block.media && (
        <div className={styles.media_wrapper} style={mediaStyle}>
          <ResolutionContent
            defaultContent={
              <Media media={block.media} fallback={block.fallbackMedia} />
            }
            mobileContent={
              block.mediaMobile && <Media media={block.mediaMobile} />
            }
          />
        </div>
      )}
    </BlockGrid>
  );
};
