import React, { FC } from 'react';
import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import { BlockProps } from '../../@types';
import { HeroInformation } from '../Hero/components/HeroInformation';
import { HeroMedia } from '../Hero/components/HeroMedia';
import { MobileButtonVariant } from '../Hero/constants';
import * as styles from './HeroSecurity.module.scss';

export type HeroSecurityProps =
  BlockProps<'STRAPI__COMPONENT_BLOCKS_V_2_HERO_SECURITY'>;

export const HeroSecurity: FC<HeroSecurityProps> = ({
  block,
  index,
  locale,
}) => {
  return (
    <div className={styles.wrapper}>
      <HeroInformation
        headlineComponent={index === 0 ? 'h1' : 'h2'}
        label={block.label}
        locale={locale}
        primaryButton={block.primaryButton}
        subtitle={block.subtitle}
        title={block.title}
      />

      <HeroMedia
        beforeContent={
          <StaticImage
            className={styles.security_background_wrapper}
            alt=""
            imgClassName={styles.security_background}
            src="../../../../../static/security/hero-bg.png"
          />
        }
        mediaLoading={index === 0 ? 'eager' : 'lazy'}
        locale={locale}
        mediaClassName={styles.media}
        mediaWrapperClassName={cn(styles.media_wrapper, {
          [styles.media_padding]: index !== 0,
        })}
        media={block.media}
        mediaMobile={block.mediaMobile}
        mobileButtonVariant={MobileButtonVariant.DOWNLOAD}
        primaryButton={block.primaryButton}
        secondaryButton={null}
      />
    </div>
  );
};
