import type { CSSProperties, FC, PropsWithChildren } from 'react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { xor } from 'lodash';

import * as styles from './Accordion.module.scss';
import type { AccordionContextValue, AccordionEventKey } from './Context';
import { AccordionContext } from './Context';

interface AccordionProps {
  alwaysOpen?: boolean;
  animationDuration?: number;
  defaultActiveKey?: AccordionEventKey;
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = memo(
  ({ children, ...rest }) => {
    const contextValue = useAccordionContextValue(rest);

    return (
      <AccordionContext.Provider value={contextValue}>
        <div
          className={styles.accordion}
          style={
            {
              '--animation-duration': `${contextValue.animationDuration}ms`,
            } as CSSProperties
          }
        >
          {children}
        </div>
      </AccordionContext.Provider>
    );
  },
);

function useAccordionContextValue({
  alwaysOpen,
  animationDuration = 300,
  defaultActiveKey,
}: AccordionProps): AccordionContextValue {
  const [activeEventKey, setActiveKey] = useState<string[]>(
    () => [defaultActiveKey].flat().filter(Boolean) as string[],
  );

  const handleSelect = useCallback(
    (eventKey: string) => {
      if (alwaysOpen) {
        setActiveKey((keys) => xor(keys, [eventKey]));
      } else {
        setActiveKey((keys) => (keys.includes(eventKey) ? [] : [eventKey]));
      }
    },
    [alwaysOpen],
  );

  const contextValue = useMemo(
    (): AccordionContextValue => ({
      activeEventKey,
      animationDuration,
      onSelect: handleSelect,
    }),
    [activeEventKey, animationDuration, handleSelect],
  );

  return contextValue;
}
