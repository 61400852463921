import React, { FC } from 'react';

import { Image } from '@/components/Image';

interface SocialNetworkItemProps {
  icon: Queries.MediaFragment['localFile'];
  href: string;
  alt: string;
}
export const SocialNetworkItem: FC<SocialNetworkItemProps> = ({
  alt,
  icon,
  href,
}) => {
  return (
    <a href={href} rel="no-follow">
      <Image width={16} height={16} alt={alt} localFile={icon} />
    </a>
  );
};
